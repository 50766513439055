import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SmartLink from '@/app/_components/SmartLink';
import { Button } from '@/app/_components/ui/button';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import { ThemeSelectors } from '@/store';

import { Spin } from 'antd';

export const FormModal = ({
	title = '',
	sizeForm = 'min-w-[90%] max-w-[95%] sm:min-w-[550px] sm:max-w-[600px]',
	labelButton,
	labelFooter,
	linkFooter,
	handleLabelFooter,
	handleSubmit,
	close,
	children,
	loading,
	isReset,
	handleReset,
	labelReset,
	isBorderFooter = true,
}: any) => {
	useLockBodyScroll();

	const theme = useSelector(ThemeSelectors.themeSelector);

	const onEsc = useCallback(
		(evt: any) => {
			if (evt?.key === 'Escape') close && close();
		},
		[close]
	);

	useEffect(() => {
		document.addEventListener('keydown', onEsc, false);
		return () => document.removeEventListener('keydown', onEsc, false);
	}, [onEsc]);

	return (
		<div className="center bg-gray-9/70 fixed inset-0 z-[199]">
			<div
				className={`flex max-h-[96%] flex-col ${sizeForm} ring-gray-2/10 rounded-xl shadow-md ring-1`}
			>
				<div
					style={{ background: theme.bg_modal_btn }}
					className="row relative h-14 rounded-t-xl p-3"
				>
					<div className="flex-1 text-center text-xl font-bold text-white">
						{title}
					</div>
					<i
						onClick={close}
						className="c4c-close absolute right-4 -mx-2 cursor-pointer px-3 py-1 text-base text-white/80 active:translate-y-px"
					/>
				</div>
				<div className="flex-1 space-y-7 overflow-y-auto bg-white px-5 py-6 sm:px-8">
					{children}
				</div>
				<div
					className={`flex flex-col space-y-6 rounded-b-xl bg-white py-3 ${
						isBorderFooter &&
						'border-t-gray-3 border-t border-solid border-x-white border-b-white'
					}`}
				>
					<div className={'row justify-center gap-4'}>
						{isReset && (
							<Button
								onClick={handleReset}
								variant="secondary"
								className="min-w-[100px]"
							>
								{loading ? <Spin size="small" /> : labelReset}
							</Button>
						)}
						{labelButton ? (
							<Button
								type="submit"
								disabled={loading}
								onClick={handleSubmit}
								style={{ background: theme.bg_modal_btn }}
								className="min-w-[100px]"
							>
								{loading ? <Spin size="small" /> : labelButton}
							</Button>
						) : null}
					</div>
					{labelFooter ? (
						<div className="center space-x-1">
							<i className="c4c-right text-[9px] text-zinc-700/70" />
							<span className="text-14 text-gray-9/80 font-normal">
								{labelFooter}
							</span>
							<div
								onClick={handleLabelFooter}
								className="text-14 text-blue-3 cursor-pointer font-bold"
							>
								{linkFooter}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

interface IProps {
	label?: string;
	type?: string;
	remark?: string;
	placeholder?: string;
	href_Link?: string;
	linkLabel?: string;
	onChange?: any;
	onKeyDown?: any;
	id?: any;
	name?: any;
	inputRef?: any;
	handleLink?: any;
	onRemark?(): void;
	value?: string;
	style?: any;
}
export const TextInputAccount = ({
	label,
	type = 'text',
	remark,
	href_Link = '#',
	placeholder,
	onChange,
	onKeyDown,
	inputRef,
	onRemark,
	linkLabel,
	handleLink,
	...restProps
}: IProps) => {
	return (
		<div className="flex flex-col">
			<div className={'row mb-1 justify-between'}>
				<p className="text-gray-9/80 text-sm font-medium">{label}</p>
				{linkLabel && (
					<button
						onClick={handleLink}
						className={
							'text-blue-3 bg-transparent p-0 text-sm font-normal'
						}
					>
						{linkLabel}
					</button>
				)}
			</div>
			<input
				className="bg-gray-1 text-gray-9/80 h-12 rounded-lg border-none px-4 text-base outline-none"
				ref={inputRef}
				type={type}
				placeholder={placeholder}
				onChange={onChange}
				onKeyDown={onKeyDown}
				{...restProps}
			/>
			{remark ? (
				<div
					className="text-14 text-blue-3 mt-3 text-right"
					onClick={onRemark}
				>
					<SmartLink href={href_Link} className="max-w-max">
						{remark}
					</SmartLink>
				</div>
			) : null}
		</div>
	);
};
