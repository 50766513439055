import React from 'react';
import { useSelector } from 'react-redux';

import Tooltip from '@/app/_components/ui/tooltip';
import { ThemeSelectors } from '@/store';

interface IProps {
	titleTooltip?: string;
	iconName?: string;
	label?: string;
	onClick?: any;
}

export const ButtonIcon = ({
	titleTooltip,
	label,
	iconName,
	onClick,
}: IProps) => {
	const theme = useSelector(ThemeSelectors.themeSelector);
	return (
		<Tooltip title={titleTooltip}>
			<button
				onClick={onClick}
				title={titleTooltip || 'quaysotrungthuong'}
				type="button"
				className="bg-primary-6 mx-auto h-10 w-10 rounded-full border border-solid border-transparent p-0 duration-300 hover:border-white hover:bg-transparent active:translate-y-[1px]"
				style={{ backgroundColor: theme.bg_btn_icon }}
			>
				{iconName ? (
					<i className={`text-xl text-white ${iconName}`} />
				) : (
					<span className={'font-semibold uppercase text-white'}>
						{label}
					</span>
				)}
			</button>
		</Tooltip>
	);
};
