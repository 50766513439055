import React, { memo } from 'react';
import { GrLanguage } from 'react-icons/gr';
import { useSelector } from 'react-redux';
import Link from 'next/link';

import Tooltip from '@/app/_components/ui/tooltip';
import { useI18n } from '@/locales/client';
import {
	AudioActions,
	AudioType,
	GlobalDispatch,
	ThemeSelectors,
} from '@/store';
import { cn } from '@/utils';

import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

function Language() {
	const { t, lang }: any = useI18n();
	const theme = useSelector(ThemeSelectors.themeSelector);
	const handleClick = () => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
	};
	return (
		<Tooltip title={t('tooltip.language') as string}>
			<Popover>
				<PopoverTrigger>
					<button
						title={t('tooltip.language') as string}
						style={{ backgroundColor: theme.bg_btn_icon }}
						onClick={handleClick}
						className="mx-auto h-10 w-10 rounded-full border border-solid border-transparent p-0 duration-300 hover:border-white hover:bg-transparent active:translate-y-[1px]"
					>
						<span
							className={
								'center text-lg font-semibold uppercase text-white'
							}
						>
							<GrLanguage />
						</span>
					</button>
				</PopoverTrigger>
				<PopoverContent>
					<div className={'col gap-1'}>
						<Link href={'/'}>
							<button
								onClick={handleClick}
								title="change language"
								className={cn(
									`btn-lang`,
									lang === 'vi' && 'bg-gray-1'
								)}
								style={{
									color:
										lang === 'vi'
											? theme.bg_btn_icon
											: '#000',
								}}
							>
								Vietnamese
							</button>
						</Link>
						<Link href={'/en'}>
							<button
								onClick={handleClick}
								title="change language"
								className={cn(
									`btn-lang`,
									lang === 'en' && 'bg-gray-1'
								)}
								style={{
									color:
										lang === 'en'
											? theme.bg_btn_icon
											: '#000',
								}}
							>
								English
							</button>
						</Link>
						<Link href={'/zh'}>
							<button
								onClick={handleClick}
								title="change language"
								className={cn(
									`btn-lang`,
									lang === 'zh' && 'bg-gray-1'
								)}
								style={{
									color:
										lang === 'zh'
											? theme.bg_btn_icon
											: '#000',
								}}
							>
								China
							</button>
						</Link>
						<Link href={'/ko'}>
							<button
								onClick={handleClick}
								title="change language"
								className={cn(
									`btn-lang`,
									lang === 'ko' && 'bg-gray-1'
								)}
								style={{
									color:
										lang === 'ko'
											? theme.bg_btn_icon
											: '#000',
								}}
							>
								Korea
							</button>
						</Link>
						<Link
							className={cn(
								`btn-lang`,
								lang === 'ja' && 'bg-gray-1'
							)}
							href={'/ja'}
							style={{
								color:
									lang === 'ja' ? theme.bg_btn_icon : '#000',
							}}
						>
							<button
								onClick={handleClick}
								title="change language"
								className={cn(
									`btn-lang`,
									lang === 'ja' && 'bg-gray-1'
								)}
							>
								Japan
							</button>
						</Link>
					</div>
				</PopoverContent>
			</Popover>
		</Tooltip>
	);
}

export default memo(Language);
