import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ButtonIcon } from '@/app/_components/Common/LDCommon';
import Footer from '@/app/_components/Footer';
import Language from '@/app/_components/Language';
import Tooltip from '@/app/_components/ui/tooltip';
import { useI18n } from '@/locales/client';
import {
	AudioActions,
	AudioType,
	AuthSelector,
	GlobalDispatch,
	ThemeSelectors,
} from '@/store';
import { GeneralActions, ModalType } from '@/store/General';
import {
	IDialSetting,
	LuckyDrawActions,
	LuckyDrawSelectors,
	ResourceTypeEnum,
} from '@/store/LuckyDraw';
import { SendEmailActions } from '@/store/SendEmail';
import { insertIf, mapV2, showToastError } from '@/utils';

import { Empty, Table, Tabs } from 'antd';

import moment from 'moment';
import * as XLSX from 'xlsx';

const LDTable = (props: {
	results: IDialSetting['prizeList'][0]['results'];
}) => {
	const { results } = props;

	const { t }: any = useI18n();
	const setting = useSelector(LuckyDrawSelectors.selectSelectedDialSetting);
	const theme = useSelector(ThemeSelectors.themeSelector);

	const participants = setting.participants as any[];
	const dataSource: any = mapV2(results, (item, index) => ({
		key: (index + 1)?.toString(),
		name: item?.name,
		ID: item?.code,
		company: participants?.find((item) => item.code === item.code)?.company,
		position: participants?.find((item) => item.code === item.code)
			?.position,
	}));

	const columns: any = useMemo(
		() => [
			{
				className: 'st-table-td',
				align: 'center',
				width: 84,
				title: 'STT',
				dataIndex: 'key',
				key: 'key',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			{
				className: 'st-table-td',
				align: 'center',
				title: t('table.code'),
				dataIndex: 'ID',
				key: 'ID',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			{
				className: 'st-table-td',
				align: 'center',
				title: t('table.name'),
				dataIndex: 'name',
				key: 'name',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			...insertIf(setting.resourceType !== ResourceTypeEnum.RANGE_NUM, {
				className: 'st-table-td',
				align: 'center',
				title: t('table.unit'),
				dataIndex: 'company',
				key: 'company',
				render: (text: string) => <p className="text-white">{text}</p>,
			}),
			...insertIf(setting.resourceType !== ResourceTypeEnum.RANGE_NUM, {
				className: 'st-table-td',
				align: 'center',
				title: t('table.position'),
				dataIndex: 'position',
				key: 'position',
				render: (text: string) => <p className="text-white">{text}</p>,
			}),
		],
		[t]
	);

	return (
		<div className="px-5">
			<div className="text-left text-sm font-medium text-slate-300">
				{t('table.date_time')}:{' '}
				<span
					className={'text-base font-semibold text-white'}
				>{`${moment().format('L')}`}</span>
			</div>
			<div id="table-custom" className="mt-4">
				<Table
					locale={{
						emptyText: (
							<Empty
								description={t('table.empty_data')}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						),
					}}
					bordered
					pagination={false}
					size="middle"
					dataSource={dataSource}
					columns={columns}

					// scroll={{y: 47*5, scrollToFirstRowOnChange: false}}
				/>
			</div>
		</div>
	);
};

const ListResults = ({
	setting,
	cls,
	handlers,
	state,
}: {
	setting?: IDialSetting;
	cls?: string;
	handlers: any;
	state: any;
}) => {
	const { t }: any = useI18n();
	const theme = useSelector(ThemeSelectors.themeSelector);
	const logged = useSelector(AuthSelector.selectLogged);
	const { prizeIndex } = state;
	const prizes = setting?.prizeList;

	const items = mapV2(prizes, (item: any, index) => ({
		key: index?.toString(),
		label: (
			<span className="text-white">
				{t(item?.name) || t('table.no_1')}
			</span>
		),
		children: <LDTable results={item?.results} />,
	}));

	const isPrizes = prizes?.some((item) => {
		return Array.isArray(item.results) && item.results.length;
	});

	const onResetPrizeList = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(LuckyDrawActions.resetResultListRequest());
	}, []);

	const onOpenSignIn = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.setModalType(ModalType.SIGN_IN));
	}, []);

	const onDownloadResult = () => {
		if (!isPrizes) {
			return showToastError(t('send_email.results.failure_message'));
		}
		/* convert state to workbook */
		const wb = XLSX.utils.book_new();
		const header = ['No.', 'Code', 'Name'];
		if (prizes?.length) {
			for (let i = 0; i < prizes?.length; i++) {
				const prize = prizes[i];
				const result = mapV2(prize?.results, (item, index) => [
					index + 1,
					item?.code,
					item?.name,
				]);
				const data = [header, ...result];
				const ws = XLSX.utils.aoa_to_sheet(data);
				XLSX.utils.book_append_sheet(
					wb,
					ws,
					t(prize?.name) || t('table.no_1')
				);
			}

			/* generate XLSX file and send to client */
			XLSX.writeFile(wb, 'prize-result.xlsx');
		}
	};

	const handleSendEmail = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		if (!logged) {
			return onOpenSignIn?.();
		}
		if (!isPrizes) {
			return showToastError(t('send_email.results.failure_message'));
		}
		GlobalDispatch(SendEmailActions.setSendEmail(setting?.prizeList));
		GlobalDispatch(GeneralActions.setModalType(ModalType.SEND_EMAIL));
	}, [logged, setting]);

	return (
		<>
			<div
				style={{ background: theme.bg_table }}
				className={`flex flex-1 flex-col overflow-hidden rounded-xl ${cls}`}
			>
				<div className="col md:row gap-3 p-5">
					<p className="flex-1 text-center text-2xl font-bold text-white md:text-left">
						{t('list.result_title')}
					</p>
					<div className="row justify-center md:justify-end">
						<Tooltip title={t('table.btn_title')}>
							<button
								className={
									'center !h-10 !w-10 rounded-full border border-solid border-transparent bg-transparent p-0 duration-300 hover:border-white hover:shadow-md'
								}
								title={t('table.btn_title') as string}
							>
								<i
									className="c4c-again cursor-pointer text-2xl text-white active:translate-y-[1px]"
									onClick={onResetPrizeList}
								/>
							</button>
						</Tooltip>
						<Tooltip title={t('table.btn_download')}>
							<button
								className={
									'center ml-2 !h-10 !w-10 rounded-full border border-solid border-transparent bg-transparent p-0 duration-300 hover:border-white hover:shadow-md'
								}
								title={t('table.btn_download')}
								onClick={onDownloadResult}
							>
								<i className="c4c-download cursor-pointer text-2xl text-white active:translate-y-[1px]" />
							</button>
						</Tooltip>
						<button
							onClick={handleSendEmail}
							title={t('table.btn_email')}
							className="text-14 ml-6 mt-0 !h-10 max-w-max cursor-pointer rounded-full border border-solid border-white bg-transparent px-2 py-2 font-semibold text-white"
						>
							{t('table.btn_email')}
						</button>
					</div>
				</div>
				<Tabs
					tabBarStyle={{
						flex: 1,
						height: 44,
						backgroundColor: theme.bg_table_prize,
						padding: '0 20px',
					}}
					items={items}
					onChange={handlers.ON_CHANGE_PRIZE_TAB}
					activeKey={prizeIndex?.toString()}
				/>
				<div className="md:row mt-4 justify-between px-5 py-6">
					<div className={'space-x-6'}>
						<ButtonIcon
							titleTooltip={t('tooltip.theme')}
							iconName="c4c-paint"
							onClick={() => handlers.ON_SHOW(ModalType.THEME)}
						/>
						<ButtonIcon
							iconName={
								state.changeSound
									? 'c4c-volume-on'
									: 'c4c-volume-off'
							}
							titleTooltip={
								state.changeSound
									? t('tooltip.off_sound')
									: t('tooltip.on_sound')
							}
							onClick={handlers.ON_SOUND}
						/>
						<Language />
					</div>
					<Footer cls={'!space-x-2'} />
				</div>
			</div>
		</>
	);
};

export default memo(ListResults);
