import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import AnimatedNumbers from '@/lib/react-animated-numbers';
import { ThemeSelectors } from '@/store';

const CircleNumber = (props: { numbers: number[] }) => {
	const theme = useSelector(ThemeSelectors.themeSelector);

	return (
		<div className="flex justify-center space-x-6">
			{props?.numbers.map((number, index) => (
				<div
					key={index.toString()}
					style={{ background: theme.gradient_border_outside }}
					className="center rounded-full p-1 shadow-md"
				>
					<div
						className={
							'center h-44 w-44 rounded-full border-none font-bold text-white'
						}
						style={{ background: theme.gradient_draw_inside }}
					>
						<AnimatedNumbers
							animateToNumber={number}
							includeComma={false}
							fontStyle={{
								fontSize: 110,
								fontWeight: 700,
								color: 'white',
							}}
							configs={[
								{
									mass: 5,
									tension: 120,
									friction: 120,
								},
							]}
							// animationType={'calm'}
							// locale={'en-US'}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default memo(CircleNumber);
