import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import AnimatedNumbers from '@/lib/react-animated-numbers';
import { ThemeSelectors } from '@/store';
import { cn } from '@/utils';

const SquareNumber = (props: { numbers: number[] }) => {
	const theme = useSelector(ThemeSelectors.themeSelector);

	const isLarge = props?.numbers?.length > 5;
	console.log(333, props?.numbers, isLarge);

	return (
		<div
			style={{ background: theme.gradient_border_outside }}
			className="flex w-full justify-center rounded-md p-[2px]"
		>
			<div
				style={{ backgroundColor: theme.bg_draw_inside }}
				className={
					'row w-full flex-wrap justify-center gap-3 rounded-md p-3'
				}
			>
				{props?.numbers.map((number, index) => (
					<div
						key={index}
						className={cn(
							'center rounded-md px-1 py-1 font-bold text-white  sm:py-6',
							isLarge ? 'sm:px-7' : 'sm:px-12'
							// isLarge ? 'sm:w-[114px]' : ''
						)}
						style={{
							background: theme.gradient_draw_inside,
							width: `${100 / props?.numbers?.length - 1}%`,
						}}
					>
						<AnimatedNumbers
							animateToNumber={number}
							includeComma={false}
							fontStyle={{
								fontSize: isLarge ? 80 : 130,
								fontWeight: 700,
								color: 'white',
							}}
							configs={[
								{
									mass: 5,
									tension: 120,
									friction: 120,
								},
							]}
							// animationType={'calm'}
							// locale={'en-US'}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default memo(SquareNumber);
