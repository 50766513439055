import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormModal } from '@/app/_components/Common/LDCommon/FormModal';
import Footer from '@/app/_components/Footer';
import Tooltip from '@/app/_components/ui/tooltip';
import { useI18n } from '@/locales/client';
import { AuthSelector, GlobalDispatch, ThemeSelectors } from '@/store';
import { GeneralActions, ModalType } from '@/store/General';
import {
	IDialSetting,
	LuckyDrawActions,
	LuckyDrawSelectors,
	ResourceTypeEnum,
} from '@/store/LuckyDraw';
import { insertIf, mapV2 } from '@/utils';

import { Empty, Table, Tabs } from 'antd';

import moment from 'moment';

const LDTable = (props: {
	results: IDialSetting['prizeList'][0]['results'];
}) => {
	const { results } = props;

	const { t }: any = useI18n();
	const setting = useSelector(LuckyDrawSelectors.selectSelectedDialSetting);
	const theme = useSelector(ThemeSelectors.themeSelector);

	const participants = setting.participants as any[];
	const dataSource: any = mapV2(results, (item, index) => ({
		key: (index + 1)?.toString(),
		name: item?.name,
		ID: item?.code,
		company: participants?.find((item) => item.code === item.code)?.company,
		position: participants?.find((item) => item.code === item.code)
			?.position,
	}));

	const columns: any = useMemo(
		() => [
			{
				className: 'st-table-td',
				align: 'center',
				width: 84,
				title: 'STT',
				dataIndex: 'key',
				key: 'key',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			{
				className: 'st-table-td',
				align: 'center',
				title: t('table.code'),
				dataIndex: 'ID',
				key: 'ID',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			{
				className: 'st-table-td',
				align: 'center',
				title: t('table.name'),
				dataIndex: 'name',
				key: 'name',
				render: (text: string) => <p className="text-white">{text}</p>,
			},
			...insertIf(setting.resourceType !== ResourceTypeEnum.RANGE_NUM, {
				className: 'st-table-td',
				align: 'center',
				title: t('table.unit'),
				dataIndex: 'company',
				key: 'company',
				render: (text: string) => <p className="text-white">{text}</p>,
			}),
			...insertIf(setting.resourceType !== ResourceTypeEnum.RANGE_NUM, {
				className: 'st-table-td',
				align: 'center',
				title: t('table.position'),
				dataIndex: 'position',
				key: 'position',
				render: (text: string) => <p className="text-white">{text}</p>,
			}),
		],
		[t]
	);

	const close = useCallback(() => {
		GlobalDispatch(GeneralActions.setModalType(null));
	}, []);

	return (
		<FormModal
			title={t('list.result_title')}
			labelButton={t('event.update_btn_label')}
			close={close}
		>
			<div className="px-5">
				<div className="text-left text-sm font-medium text-slate-300">
					{t('table.date_time')}:{' '}
					<span
						className={'text-base font-semibold text-white'}
					>{`${moment().format('L')}`}</span>
				</div>
				<div id="table-custom" className="mt-4">
					<Table
						locale={{
							emptyText: (
								<Empty
									description={t('table.empty_data')}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							),
						}}
						bordered
						pagination={false}
						size="middle"
						dataSource={dataSource}
						columns={columns}

						// scroll={{y: 47*5, scrollToFirstRowOnChange: false}}
					/>
				</div>
			</div>
		</FormModal>
	);
};

const ModalResults = ({
	onChange,
	setting,
	prizeIndex,
	children,
	cls,
}: {
	onChange?: ((activeKey: string) => void) | undefined;
	setting?: any;
	prizeIndex?: number;
	onShowListOption?: any;
	onClose?: any;
	children?: any;
	cls?: string;
}) => {
	const { t }: any = useI18n();
	const theme = useSelector(ThemeSelectors.themeSelector);
	const logged = useSelector(AuthSelector.selectLogged);

	const items = mapV2(setting?.prizeList, (item: any, index) => ({
		key: index?.toString(),
		label: (
			<span className="text-white">
				{t(item?.name) || t('table.no_1')}
			</span>
		),
		children: <LDTable results={item?.results} />,
	}));

	const onResetPrizeList = useCallback(() => {
		GlobalDispatch(LuckyDrawActions.resetResultListRequest());
	}, []);

	const onOpenSignIn = useCallback(() => {
		GlobalDispatch(GeneralActions.setModalType(ModalType.SIGN_IN));
	}, []);

	const handleSentEmail = useCallback(() => {
		if (!logged) {
			return onOpenSignIn?.();
		}
	}, [logged]);

	return (
		<>
			<div
				style={{ background: theme.bg_table }}
				className={`flex flex-1 flex-col overflow-hidden rounded-xl ${cls}`}
			>
				<div className="col md:row gap-3 p-5">
					<p className="flex-1 text-center text-2xl font-bold text-white md:text-left">
						{t('list.result_title')}
					</p>
					<div className="row justify-center md:justify-end">
						<Tooltip title={t('table.btn_title')}>
							<button
								className={
									'center !h-10 !w-10 rounded-full border border-solid border-transparent bg-transparent p-0 duration-300 hover:border-white hover:shadow-md'
								}
								title={t('table.btn_title') as string}
							>
								<i
									className="c4c-again cursor-pointer text-2xl text-white active:translate-y-[1px]"
									onClick={onResetPrizeList}
								/>
							</button>
						</Tooltip>
						<button
							className={
								'center ml-2 !h-10 !w-10 rounded-full border border-solid border-transparent bg-transparent p-0 duration-300 hover:border-white hover:shadow-md'
							}
							title="download"
						>
							<i className="c4c-download cursor-pointer text-2xl text-white active:translate-y-[1px]" />
						</button>
						<button
							onClick={handleSentEmail}
							title="email"
							className="text-14 ml-6 mt-0 !h-10 max-w-max cursor-pointer rounded-full border border-solid border-white bg-transparent px-2 py-2 font-semibold text-white"
						>
							{t('table.btn_email')}
						</button>
					</div>
				</div>
				<Tabs
					tabBarStyle={{
						flex: 1,
						height: 44,
						backgroundColor: theme.bg_table_prize,
						padding: '0 20px',
					}}
					items={items}
					onChange={onChange}
					activeKey={prizeIndex?.toString()}
				/>
				<div className="md:row mt-4 justify-between px-5 py-6">
					{children}
					<Footer cls={'!space-x-2'} />
				</div>
			</div>
		</>
	);
};

export default ModalResults;
