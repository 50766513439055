'use client';
import React from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { ButtonIcon } from '@/app/_components/Common/LDCommon';
import OutSideListActions from '@/app/_components/ListActions';
import Modal from '@/app/_components/ModalConfig';
import CircleNumber from '@/app/[locale]/home/AnimatedNumber/Circle';
import { ThemeSelectors } from '@/store';
import { ModalType } from '@/store/General';

import useHomeLogic from './Home.logic';

const Congratulation = dynamic(
	() => import('@/app/_components/Effect/Congratulation'),
	{
		ssr: false,
	}
);

const HomeView = () => {
	const {
		handlers,
		state,
		selectedEvent,
		drawDone,
		ending,
		eventList,
		setting,
		setState,
		restAutoSpinNumber,
		isAutoDraw,
		imageBadge,
		btnMainLabel,
		t,
	} = useHomeLogic();
	const theme = useSelector(ThemeSelectors.themeSelector);
	return (
		<>
			<div className="container relative z-20 space-y-10 overflow-x-hidden px-4 text-center lg:px-0">
				<h1 className="mx-auto !mt-0 max-w-max text-center text-[40px] font-extrabold leading-normal text-white drop-shadow-md">
					{selectedEvent.id ? selectedEvent?.name : t('home.title')}
				</h1>
				{state.showCongratulation && (
					<Congratulation handlers={handlers} />
				)}
				<div className="center space-x-3 ">
					<button
						title={'previous'}
						className={'center bg-transparent !p-2 py-0'}
						onClick={handlers.ON_PREVIOUS_PRIZE}
					>
						<i
							style={{ color: theme.bg_btn_icon }}
							className="c4c-right rotate-180 text-lg"
						/>
					</button>
					<Image
						src={imageBadge}
						alt={'prize badge'}
						width={75}
						height={80}
						placeholder="blur"
					/>
					<button
						className={'center bg-transparent !p-2 py-0'}
						onClick={handlers.ON_NEXT_PRIZE}
						title="next"
					>
						<i
							style={{ color: theme.bg_btn_icon }}
							className="c4c-right text-lg"
						/>
					</button>
				</div>

				<CircleNumber numbers={state.numbers} />
				<div className="">
					<div className="center space-x-4">
						{/* <i className="c4c-shuff cursor-pointer text-lg text-white active:translate-y-[1px]" /> */}
						<div className="flex md:hidden">
							<ButtonIcon
								titleTooltip={t('list.result_title')}
								iconName="c4c-sidebar-left"
								onClick={() =>
									handlers.ON_SHOW(ModalType.LIST_RESULT)
								}
							/>
						</div>

						<button
							disabled={drawDone || ending}
							style={{ background: theme.bg_btn }}
							className="from-yellow-3 to-yellow-4 max-w-max cursor-pointer rounded-md border-none bg-gradient-to-t px-8 py-3 text-lg font-semibold uppercase text-white duration-200 active:translate-y-[1px]"
							onClick={handlers.ON_CLICK_MAIN_BTN}
						>
							{btnMainLabel}
						</button>
						<div className="flex md:hidden">
							<ButtonIcon
								titleTooltip={t('list.event_title')}
								iconName="c4c-menu"
								onClick={() =>
									handlers.ON_SHOW(ModalType.LIST_EVENT)
								}
							/>
						</div>
						{/* <i className="c4c-again cursor-pointer text-lg text-white active:translate-y-[1px]" /> */}
					</div>
					{isAutoDraw && restAutoSpinNumber > 1 && (
						<div className="mt-6 text-center text-[14px] text-[#C7C7C7]">
							{`Còn ${restAutoSpinNumber} lần quay tự động`}
						</div>
					)}

					<OutSideListActions {...{ handlers, t, state }} />
				</div>
			</div>
			<Modal
				{...{
					theme,
					state,
					handlers,
					setting,
					setState,
					eventList,
					selectedEvent,
					t,
				}}
			/>
		</>
	);
};

export default HomeView;
