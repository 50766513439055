import React, { memo } from 'react';
import dynamic from 'next/dynamic';

import { ButtonIcon } from '@/app/_components/Common/LDCommon';
import Footer from '@/app/_components/Footer';
import { ModalType } from '@/store/General';

const Language = dynamic(() => import('@/app/_components/Language'), {
	ssr: false,
});

function ListAction({ handlers, t, state }: any) {
	return (
		<>
			<div className="mt-10 space-x-6">
				{/* <ButtonIcon iconName='c4c-image'/> */}
				<ButtonIcon
					titleTooltip={t('tooltip.theme')}
					iconName="c4c-paint"
					onClick={() => handlers.ON_SHOW(ModalType.THEME)}
				/>
				<ButtonIcon
					iconName={
						state.changeSound ? 'c4c-volume-on' : 'c4c-volume-off'
					}
					titleTooltip={
						state.changeSound
							? t('tooltip.off_sound')
							: t('tooltip.on_sound')
					}
					onClick={handlers.ON_SOUND}
				/>
				<Language />
			</div>
			<Footer cls={'mt-12'} />
		</>
	);
}

export default memo(ListAction);
